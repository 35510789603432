<template>
  <main class="dg-ppm-info-section">
    <h1 class="dg-ppm-info-section__title">{{ $t("ppm.info.title") }}</h1>
    <p class="dg-ppm-info-section__content">{{ $t("ppm.info.content.first_para") }}</p>
    <p class="dg-ppm-info-section__content">{{ $t("ppm.info.content.second_para") }}</p>
    <strong class="dg-ppm-info-section__content">{{ leadConsultantName }}</strong>
    <article class="dg-ppm-info-section__info">
      <article class="dg-ppm-info-section__dpa-info">
        <h2 class="dg-ppm-info-section__info__title">{{ $t("ppm.info.dpa.title") }}</h2>
        <p class="dg-ppm-info-section__info__content" v-html="$t('ppm.info.dpa.content')"></p>
        <article class="dg-ppm-info-section__data-subject">
          <img src="../images/data-subject.svg" alt="" />
          <section class="dg-ppm-info-section__data-subject__section">
            <h4 class="dg-ppm-info-section__data-subject__title">{{ $t("ppm.info.data_subject.title") }}</h4>
            <p class="dg-ppm-info-section__data-subject__content">{{ $t("ppm.info.data_subject.subtitle") }}</p>
            <p class="dg-ppm-info-section__data-subject__content">{{ $t("ppm.info.data_subject.content") }}</p>
          </section>
        </article>
        <img class="dg-ppm-info-section__relation-arrow" src="../images/dpa-arrow.svg" alt="" />
        <article class="dg-ppm-info-section__contract-relation dpa">
          <section class="dg-ppm-info-section__contract-type-card primary">
            <h4 class="dg-ppm-info-section__contract-type-card__title primary">
              {{ $t("ppm.info.contract_relation.dpa.controller.title") }}
            </h4>
            <p class="dg-ppm-info-section__contract-type-card__content">
              {{ $t("ppm.info.contract_relation.dpa.controller.subtitle") }}
            </p>
            <p class="dg-ppm-info-section__contract-type-card__content">
              {{ $t("ppm.info.contract_relation.dpa.controller.content") }}
            </p>
          </section>
          <img class="dg-ppm-info-section__contract-relation__dpa-icon" src="../images/dpa-relation.svg" alt="" />
          <section class="dg-ppm-info-section__contract-type-card">
            <h4 class="dg-ppm-info-section__contract-type-card__title">
              {{ $t("ppm.info.contract_relation.dpa.processor.title") }}
            </h4>
            <p class="dg-ppm-info-section__contract-type-card__content">
              {{ $t("ppm.info.contract_relation.dpa.processor.subtitle") }}
            </p>
            <p class="dg-ppm-info-section__contract-type-card__content">
              {{ $t("ppm.info.contract_relation.dpa.processor.content") }}
            </p>
          </section>
          <section class="dg-ppm-info-section__contract-flag dpa">
            <h3 class="dg-ppm-info-section__contract-flag__title">
              {{ $t("ppm.info.contract_relation.dpa.title") }}
            </h3>
            <img class="dg-ppm-info-section__contract-flag__icon" src="../images/contract-lock.svg" alt="" />
          </section>
        </article>
      </article>
      <article class="dg-ppm-info-section__jca-info">
        <h2 class="dg-ppm-info-section__info__title">{{ $t("ppm.info.jca.title") }}</h2>
        <p class="dg-ppm-info-section__info__content" v-html="$t('ppm.info.jca.content')"></p>
        <article class="dg-ppm-info-section__data-subject">
          <img src="../images/data-subject.svg" alt="" />
          <section class="dg-ppm-info-section__data-subject__section">
            <h4 class="dg-ppm-info-section__data-subject__title">{{ $t("ppm.info.data_subject.title") }}</h4>
            <p class="dg-ppm-info-section__data-subject__content">{{ $t("ppm.info.data_subject.subtitle") }}</p>
            <p class="dg-ppm-info-section__data-subject__content">{{ $t("ppm.info.data_subject.content") }}</p>
          </section>
        </article>
        <img class="dg-ppm-info-section__relation-arrow" src="../images/jca-arrow.svg" alt="" />
        <article class="dg-ppm-info-section__contract-relation jca">
          <section class="dg-ppm-info-section__contract-type-card primary">
            <h4 class="dg-ppm-info-section__contract-type-card__title primary">
              {{ $t("ppm.info.contract_relation.jca.controller_title") }}
            </h4>
            <p class="dg-ppm-info-section__contract-type-card__content">
              {{ $t("ppm.info.contract_relation.jca.first_controller_content") }}
            </p>
          </section>
          <img class="dg-ppm-info-section__contract-relation__jca-icon" src="../images/jca-relation.svg" alt="" />
          <section class="dg-ppm-info-section__contract-type-card">
            <h4 class="dg-ppm-info-section__contract-type-card__title">
              {{ $t("ppm.info.contract_relation.jca.controller_title") }}
            </h4>
            <p class="dg-ppm-info-section__contract-type-card__content">
              {{ $t("ppm.info.contract_relation.jca.second_controller_content") }}
            </p>
          </section>
          <section class="dg-ppm-info-section__contract-flag jca">
            <h3 class="dg-ppm-info-section__contract-flag__title">
              {{ $t("ppm.info.contract_relation.jca.title") }}
            </h3>
            <img class="dg-ppm-info-section__contract-flag__icon" src="../images/contract-lock.svg" alt="" />
          </section>
        </article>
      </article>
    </article>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PPMDashboard",
  computed: {
    leadConsultantName() {
      return `${this.companyData?.main_contact_dpo?.first_name} ${this.companyData?.main_contact_dpo?.last_name}`;
    },
    ...mapGetters(["companyData"]),
  },
};
</script>

<style lang="scss">
.dg-ppm-info-section {
  color: #003349;
  flex: 1;
  padding: 32px;
  max-width: 1140px;
  margin: 0 auto;

  &__title,
  &__content,
  &__info__title,
  &__info__content,
  &__data-subject__title,
  &__data-subject__content,
  &__contract-type-card__title,
  &__contract-type-card__content,
  &__contract-flag__title {
    margin: 0;
  }

  &__title {
    font-size: 40px;
    line-height: 56px;
  }

  &__content {
    font-size: 16px;
    line-height: 24px;
    margin: 9px 0;
  }

  &__info {
    display: flex;
    background-color: $white;
    padding: 24px;
    margin-top: 36px;

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__content {
      font-size: 16px;
      line-height: 24px;
      margin: 9px 0;

      & a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:active {
          color: inherit;
        }
      }
    }
  }

  &__dpa-info {
    border-right: 1px solid #c9d2d7;
    padding-right: 24px;
  }

  &__jca-info {
    padding-left: 24px;
  }

  &__data-subject {
    display: flex;
    max-width: 275px;
    margin: 0 auto;
    margin-top: 72px;
    align-items: center;

    &__section {
      margin-left: 9px;
    }

    &__title,
    &__content {
      font-size: 12px;
      line-height: 16px;
    }

    &__title {
      color: #cb333b;
    }
  }

  &__relation-arrow {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 95px;
    z-index: 1;
  }

  &__contract-relation {
    position: relative;
    display: flex;
    background-color: #e9edef;
    margin: 0 auto;
    padding: 16px;

    &.dpa {
      max-width: 386px;
      flex-direction: column;
      justify-content: center;
      margin-top: -16px;
    }

    &.jca {
      max-width: 450px;
      justify-content: space-between;
      align-items: center;

      & > section {
        max-width: 160px;
      }
    }

    &__dpa-icon {
      height: 90px;
    }

    &__jca-icon {
      height: 50px;
    }
  }

  &__contract-type-card {
    background-color: $white;
    padding: 8px;
    border: 2px solid #7e93a7;

    &.primary {
      border-color: #cb333b;
    }

    &__title,
    &__content {
      font-size: 12px;
      line-height: 16px;
    }

    &__title {
      margin-bottom: 3px;

      &.primary {
        color: #cb333b;
      }
    }
  }

  &__contract-flag {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    background-color: #254a5d;
    color: $white;
    width: 82px;
    padding: 5px;
    box-shadow: 0px 3px 16px 2px rgba(0, 0, 0, 0.15);

    &:after {
      content: "";
      position: absolute;
      border-style: solid;
    }

    &.dpa {
      right: -12px;

      &:after {
        top: -12px;
        right: 0;
        border-width: 12px 0 0 12px;
        border-color: transparent transparent transparent #000000;
      }
    }

    &.jca {
      left: calc(50% - 41px);
      bottom: -12px;

      &:after {
        left: -12px;
        bottom: 0;
        border-width: 0 12px 12px 0;
        border-color: transparent #000000 transparent transparent;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
    }

    &__icon {
      height: 24px;
      align-self: flex-end;
    }
  }
}
</style>
